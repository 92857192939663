import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ImageWithPortraitCheck from '../../../../components/ImageWithPortraitCheck';

import fluidImagePropTypes from '../../../../constants/propTypes';
import s from './BlogSnippet.module.scss';

const averageWordsReadPerMin = 200;

const propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  createdAt: PropTypes.string.isRequired,
  image: PropTypes.shape({
    fluid: PropTypes.shape(fluidImagePropTypes),
  }),
  description: PropTypes.shape({
    json: PropTypes.shape({}),
  }),
  wordCount: PropTypes.number,
};

const defaultProps = {
  subtitle: '',
  image: {
    fluid: null,
  },
  description: {
    json: {},
  },
  wordCount: 0,
};

const BlogSnippet = ({ title, url, subtitle, createdAt, image, wordCount }) => {
  const readTime = Math.ceil(wordCount / averageWordsReadPerMin);
  return (
    <div className={s.snippetContainer} data-aos="fade-up" data-aos-delay="300">
      <Link to={url} className={s.snippetImage}>
        {image && image.fluid && <ImageWithPortraitCheck image={image} className={s.snippetImg} />}
      </Link>
      <div className={image ? [s.snippetInfo, s.snippetWithImage].join(' ') : s.snippetInfo}>
        <Link to={url} className={s.snippetImage}>
          <h3 className={s.snippetTitle}>{title}</h3>
          {subtitle && <p className={s.snippetDescription}>{subtitle}</p>}
        </Link>
        <p className={s.snippetPublished}>
          <span className={s.createdTime}>{`${createdAt} • `}</span>
          <span className={s.snippetReadTime}>{`${readTime} min read`}</span>
        </p>
      </div>
    </div>
  );
};

BlogSnippet.propTypes = propTypes;
BlogSnippet.defaultProps = defaultProps;
export default BlogSnippet;
