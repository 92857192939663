import PropTypes from 'prop-types';

/* Gatsby Image Prop Types
 * Found here: https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-image/src/index.js#L730
 */
const fluidImagePropTypes = {
  aspectRatio: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string.isRequired,
  sizes: PropTypes.string.isRequired,
  base64: PropTypes.string,
  tracedSVG: PropTypes.string,
  srcWebp: PropTypes.string,
  srcSetWebp: PropTypes.string,
  media: PropTypes.string,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
};

export default fluidImagePropTypes;
