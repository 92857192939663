import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import CompanyQuote from '../../components/CompanyQuote';
import { getSeoConfig } from '../../constants/defaultSeoProps';
import BlogList from './BlogList';
import BlogPagination from './BlogPagination';

const propTypes = {
  blogs: PropTypes.array.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
  companyQuote: PropTypes.shape({
    title: PropTypes.string,
    quote: PropTypes.string,
    footerDescription: PropTypes.shape({}).isRequired,
    icons: PropTypes.arrayOf({
      fluid: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/blogs.png',
  ogTitle: 'Techblog | Tech Holding',
  ogDesc:
    "Stay informed and inspired with TechHolding's insightful blog. Dive into the latest trends, expert insights, and valuable tips shaping the future of technology and business.",
  ogPath: '/blog',
};

const updatedSeo = getSeoConfig(seoParams);

const Blog = ({ blogs, pageInfo, companyQuote }) => (
  <Layout
    currentPage="/blog"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
    footerDescription={companyQuote.footerDescription}
  >
    <PageHeader
      title="Our Blogs"
      description="A collection of our footprints, stories, remarkable tips, and design insights for the others"
    />
    <BlogList blogEntries={blogs} />
    <BlogPagination pageInfo={pageInfo} />
    <CompanyQuote companyQuote={companyQuote} />
  </Layout>
);

Blog.propTypes = propTypes;
export default Blog;
