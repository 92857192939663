// Merges the text from the nested objects in blog.description
export const getOpenGraphBlurb = (blogDescription) => {
  const blurb = blogDescription.json.content
    .filter(({ nodeType }) => nodeType !== 'embedded-entry-block')
    .map(({ content }) => content.map(({ value }) => value).join(' '))
    .join(' ');
  return blurb;
};

export const getEmbeddedContent = (blogDescription) => {
  const embeddedContent = blogDescription.json.content
    .filter(({ nodeType }) => nodeType === 'embedded-entry-block')
    .map(({ data }) => (data?.target?.fields ? data.target.fields : {}))
    .map(
      (fields) => (fields.markdownText || {})['en-US'] || (fields.quoteText || {})['en-US'] || '',
    )
    .join(' ');
  return embeddedContent;
};

export const getNumWords = (description) => {
  if (!description) return 0;
  const blurb = getOpenGraphBlurb(description);
  const embeddedContent = getEmbeddedContent(description);
  const count = blurb.split(' ').length + embeddedContent.split(' ').length;
  return count;
};
