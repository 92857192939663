import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';

const propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
    }),
  }).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const ImageWithVerticalCheck = ({ image, className }) => {
  const verticalImage = image.fluid.aspectRatio <= 1;
  const imageStyle = {
    width: 'auto',
    top: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
  };
  return (
    <div className={className}>
      <Image fluid={image.fluid} className={className} imgStyle={verticalImage ? imageStyle : {}} />
    </div>
  );
};
ImageWithVerticalCheck.propTypes = propTypes;
ImageWithVerticalCheck.defaultProps = defaultProps;
export default ImageWithVerticalCheck;
