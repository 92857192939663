import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../../components/Button';
import limitButtonLength from '../../../utils/BlogPagination/blog-pagination';

import s from './BlogPagination.module.scss';

const propTypes = {
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    currentPage: PropTypes.number,
    pageCount: PropTypes.number,
  }).isRequired,
};

const BlogPagination = ({ pageInfo: { hasNextPage, hasPreviousPage, currentPage, pageCount } }) => {
  const renderButtons = () => {
    const delta = 1; // distance between currentPage and an ellipses
    const left = currentPage - delta; // left boundary page before an ellipses
    const right = currentPage + delta + 1; // right boundary

    /* returns [1, null, 3, 4, 5, null, 7], nulls to be replaced with ... */
    const numbers = Array.from({ length: pageCount }).map((_, i) => {
      const pageNumber = i + 1;
      if (
        pageNumber === 1 ||
        pageNumber === pageCount ||
        (pageNumber >= left && pageNumber < right)
      ) {
        return pageNumber;
      }
      return null;
    });

    const removedDuplicateNulls = numbers.filter((_, i) => {
      if (numbers[i] === numbers[i + 1]) return false;
      return true;
    });

    const removedExtraButtons = limitButtonLength(removedDuplicateNulls, pageCount, currentPage);

    const pageButtons = removedExtraButtons.map((pageNumber) => {
      const isCurrentPage = currentPage === pageNumber;
      if (pageNumber === null) {
        return <Button className={classnames(s.pageButton, s.nullButton)}>{`...`}</Button>;
      }

      return (
        <Link to={pageNumber === 1 ? `/blog/` : `/blog/page/${pageNumber}`}>
          <Button
            theme={isCurrentPage ? 'blue' : 'grey'}
            className={classnames(s.pageButton, isCurrentPage && s.current)}
          >
            {pageNumber}
          </Button>
        </Link>
      );
    });

    return pageButtons;
  };

  return (
    <section className={s.root}>
      {hasPreviousPage && (
        <Link to={currentPage === 2 ? `/blog/` : `/blog/page/${currentPage - 1}`}>
          <Button className={s.pageButton}>◀</Button>
        </Link>
      )}
      {renderButtons()}
      {hasNextPage && (
        <Link to={`/blog/page/${currentPage + 1}`}>
          <Button className={s.pageButton}>▶</Button>
        </Link>
      )}
    </section>
  );
};

BlogPagination.propTypes = propTypes;
export default BlogPagination;
