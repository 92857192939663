import React from 'react';
import PropTypes from 'prop-types';
import { getNumWords } from '../../../utils/blog-content-helpers';
import BlogSnippet from './BlogSnippet';
import s from './BlogList.module.scss';

const propTypes = {
  blogEntries: PropTypes.array.isRequired,
};

const BlogList = ({ blogEntries }) => {
  const blogSnippetList = [];
  blogEntries.forEach(({ slug, newSlug, redirect, description, ...blogInfo }) => {
    if (!redirect && !newSlug) {
      blogSnippetList.push(
        <BlogSnippet
          key={slug}
          url={`/blog/${slug}`}
          wordCount={getNumWords(description)}
          description={description}
          {...blogInfo}
        />,
      );
    }
  });

  return (
    <div className={s.container}>
      <div className={s.blogRow}>{blogSnippetList}</div>
    </div>
  );
};

BlogList.propTypes = propTypes;
export default BlogList;
