// Fix the array to always be of length 5.
// Left and right arrows are reserved.
// Design wants to have a max of 7 buttons shown at any time
const limitButtonLength = (buttonArray, pageCount = 0, currentPage = 0) => {
  if (buttonArray.length <= 5) {
    return buttonArray;
  }

  const currentPageIndex = buttonArray.indexOf(currentPage);
  const mid = Math.floor(pageCount / 2);

  // If buttonArray is of length 6 and currentPage is greater than half,
  // then remove currentPage - 1. otherwise remove currentPage + 1
  // Ex: < 1 2 3 4 ... 8 > where 3 is the currentPage. Remove 4.
  // Ex: < 1 ... 5 6 7 8 > where 6 is the currentPage. Remove 5.
  if (buttonArray.length === 6) {
    return [
      ...buttonArray.slice(0, currentPage > mid ? currentPageIndex - 1 : currentPageIndex + 1),
      ...buttonArray.slice(currentPage < mid ? currentPageIndex + 2 : currentPageIndex),
    ];
  }

  // Remove middle neighboring buttons
  // Ex: < 1 ... 3 4 5 ... 8 > where 4 is the currentPage. Remove 3 and 5.
  return [
    ...buttonArray.slice(0, currentPageIndex - 1),
    currentPage,
    ...buttonArray.slice(currentPageIndex + 2),
  ];
};

export default limitButtonLength;
