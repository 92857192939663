import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Blog from '../../containers/Blog';

const propTypes = {
  data: PropTypes.shape({
    allContentfulBlog: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
      pageInfo: PropTypes.shape({
        hasNextPage: PropTypes.bool,
        hasPreviousPage: PropTypes.bool,
      }).isRequired,
    }).isRequired,
    companyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf({
        fluid: PropTypes.shape({}),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const BlogsIndex = ({ data }) => {
  return (
    <Blog
      blogs={data.allContentfulBlog.nodes}
      pageInfo={data.allContentfulBlog.pageInfo}
      companyQuote={data.companyQuote}
    />
  );
};

BlogsIndex.propTypes = propTypes;
export default BlogsIndex;

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulBlog(sort: { order: DESC, fields: createdAt }, skip: $skip, limit: $limit) {
      nodes {
        title
        subtitle
        slug
        createdAt(formatString: "MMM D, YYYY")
        redirect
        newSlug
        updatedAt(formatString: "MMM D, YYYY")
        image {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyContentfulFluid_withWebp
            aspectRatio
          }
        }
        description {
          json
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    companyQuote: contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
      icons {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;
